import { ComplexNum, modulus, muls, polar } from './complex';
import { asEuclideanDistance, asHyperbolicDistance, normalizeAngle, poincareCline, PoincareCline, rotate, translate } from './hyperbolic';

export default class Vector {
    r: number

    private _theta: number

    get theta(): number {
        return this._theta;
    }

    set theta(theta:number) {
        this._theta = normalizeAngle(theta);
    }

    constructor(r:number, theta:number) {
        this.r = r;
        this._theta = theta;
    }

    translate(a: ComplexNum, multiplier: number): PoincareCline {
        const r = this.r * multiplier;
        const x = Math.tanh(r/2);
        const b = translate(muls(a, -1), rotate(this._theta, { x, y: 0 }));
        const cline = poincareCline(a, b);
        if (cline.isArc) {
            const { alpha, beta } = cline;
            this.theta -= alpha - beta;
        }
        return cline;
    }

    add(v:Vector): void {
        // https://math.stackexchange.com/questions/1365622/adding-two-polar-vectors
        const dtheta = v.theta-this.theta;
        const r = Math.sqrt(this.r*this.r + v.r*v.r + 2*this.r*v.r*Math.cos(dtheta));
        const theta = this.theta + Math.atan2(v.r*Math.sin(dtheta),this.r+v.r*Math.cos(dtheta));
        this.r = r;
        this.theta = theta;
    }

    clone(): Vector {
        return new Vector(this.r, this._theta);
    }
}

export function wrapPos(radius: number, p:ComplexNum): boolean {
    const h = asHyperbolicDistance(modulus(p));
    if (h >= radius) {
        const theta = Math.atan2(p.y, p.x);
        const h2 = 2*radius - h;
        const p2 = polar(asEuclideanDistance(h2), normalizeAngle(Math.PI + theta));
        p.x = p2.x;
        p.y = p2.y;
        return true;
    } else {
        return false;
    }
}

export type Movable = {
    pos: ComplexNum
    vel: Vector
    dir?: number
}

export function move(entity:Movable, dt:number, wrapRadius?: number) {
    const cline = entity.vel.translate(entity.pos, dt);
    entity.pos = cline.b;
    if (typeof wrapRadius !== 'undefined' && wrapPos(wrapRadius, entity.pos)) {
        // TODO: Do we want to change the direction somehow?
        /*
        entity.vel.theta += Math.PI;
        entity.dir = normalizeAngle(entity.dir + Math.PI);
        */
    }
    if (cline.isArc && typeof entity.dir !== 'undefined') {
        const { alpha, beta } = cline;
        entity.dir -= normalizeAngle(alpha - beta);
    }

}