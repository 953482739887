
export type Key = 'ArrowLeft' | 'ArrowRight' | 'ArrowUp' | 'Control' | 'p' | 'Enter';
const KEYS: string[] = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'Control', 'p', 'Enter'];

function handledKey(key:string): key is Key {
    return KEYS.indexOf(key) >= 0;
}

type KeyState = Record<Key,boolean>

export const keyState: KeyState = {
    ArrowLeft: false,
    ArrowRight: false,
    ArrowUp: false,
    Control: false,
    Enter: false,
    p: false,
};


document.addEventListener('keydown', (e) => {
    if (handledKey(e.key)) {
        keyState[e.key] = true;
    }
});

document.addEventListener('keyup', (e) => {
    if (handledKey(e.key)) {
        keyState[e.key] = false;
    }
});