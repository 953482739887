export const Constants = {
    ShipLen: 0.06,
    MaxSpeed: 0.5,
    ShipAccel: 0.5,
    RotSpeed: 2*Math.PI/1.5,
    Origin: { x: 0, y: 0 },
    InitialAsteroidNum: 10,
    MaxBullets: 5,
    ReloadTime: 0.20,
    BulletSpeed: 1,
    BulletLifetime: 2.5,
    WorldRadius: 3.5,
}