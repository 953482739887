import { HyperbolicCanvas } from "./canvas";
import { ComplexNum } from "./complex";
import { Constants } from "./constants";
import Vector, { Movable, move } from "./vector";

export type Bullet = {
    pos: ComplexNum,
    vel: Vector,
    firedTime: number
}

export type StateWithBullets = {
    bullets: Set<Bullet>
}

export function fireBullet(state: StateWithBullets, t: number, from:Movable, inDir?:number) {
    var dir: number;
    if (typeof from.dir === 'undefined') {
        if (typeof inDir === 'undefined') {
            throw new Error('Cannot fire bullet from directionless object without specifying inDir');
        }
        dir = inDir;
    } else {
        dir = from.dir;
    }
    const vel = new Vector(Constants.BulletSpeed, dir);
    vel.add(from.vel);
    state.bullets.add({ pos: from.pos, vel, firedTime: t });
}

export function updateBullets(state: StateWithBullets, t:number, dt: number) {
    const { bullets } = state;
    for (const b of bullets) {
        if (t - b.firedTime > Constants.BulletLifetime) {
            bullets.delete(b);
        } else {
            move(b, dt, Constants.WorldRadius);
        }
    }
}

export function drawBullets(state: StateWithBullets, canvas: HyperbolicCanvas) {
    for (const b of state.bullets) {
        const _p = canvas.toCanvas(b.pos);
        canvas.ctx.fillStyle = 'white';
        canvas.ctx.beginPath();
        canvas.ctx.ellipse(_p.x, _p.y, 1, 1, 0, 0, Math.PI*2);
        canvas.ctx.fill();
    }
}