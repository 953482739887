import Color from "color";
import { ComplexNum } from "./complex";
import { normalizeAngle, poincareCline, PoincareCline, rotateAndTranslate } from "./hyperbolic";

export type Target = CanvasRenderingContext2D | Path2D

export type Drawable = {
    pos: ComplexNum
    dir?: number
    shape: Shape
}

export interface CollisionService {
    isPointIn(obj:Drawable, p:ComplexNum): boolean
}

export type StateWithCollisionService = {
    collisionSvc: CollisionService
}

export type LineList = Array<ComplexNum>

export type Shape = Array<LineList>

export class HyperbolicCanvas implements CollisionService {
    readonly canvas: HTMLCanvasElement
    readonly ctx: CanvasRenderingContext2D
    private prevEndpoint: ComplexNum;

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        const ctx = canvas.getContext('2d');
        if (ctx === null) {
            throw new Error('Failed to create graphics context');
        }
        this.ctx = ctx;
        this.prevEndpoint = { x: 0, y: 0 };
    }

    get scale(): number {
        return 0.5 * Math.min(this.canvas.width, this.canvas.height);
    }

    get width(): number {
        return this.canvas.width;
    }

    get height(): number {
        return this.canvas.height;
    }

    toCanvas(p:ComplexNum): ComplexNum {
        const s = this.scale;
        return {
            x: p.x * s + this.canvas.width / 2,
            y: p.y * s + this.canvas.height / 2,
        }
    }

    fromCanvas(p:ComplexNum): ComplexNum {
        const s = this.scale;
        return {
            x: (p.x - this.canvas.width / 2) / s,
            y: (p.y - this.canvas.height / 2) / s,
        }
    }

    moveTo(p:ComplexNum, target:Target = this.ctx) {
        this.prevEndpoint = Object.assign({}, p);
        const _p = this.toCanvas(p);
        target.moveTo(_p.x, _p.y);
    }

    lineTo(p:ComplexNum, target:Target = this.ctx) {
        const _p = this.toCanvas(p);
        target.lineTo(_p.x, _p.y);
    }

    private drawCline(cline: PoincareCline, target:Target = this.ctx) {
        if (cline.isArc && cline.radius < 100) {
            const { center, radius, alpha, beta } = cline;
            const _center = this.toCanvas(center);
            target.arc(_center.x, _center.y, this.scale * radius, alpha, beta, normalizeAngle(alpha - beta) < Math.PI );
        } else {
            this.lineTo(cline.b, target);
        }
    }

    clineTo(q:ComplexNum, target:Target = this.ctx) {
        const p = this.prevEndpoint;
        this.prevEndpoint = q;
        this.drawCline(poincareCline(p, q), target);
    }

    strokeCline(cline: PoincareCline) {
        this.ctx.beginPath();
        this.moveTo(cline.a);
        this.drawCline(cline);
        this.ctx.stroke();
    }

    fillDot(p:ComplexNum, radius: number=1, color?: Color|string) {
        const _p = this.toCanvas(p);
        this.ctx.beginPath();
        this.ctx.fillStyle = color+""
        this.ctx.ellipse(_p.x, _p.y, radius/2, radius/2, 0, 0, 2*Math.PI);
        this.ctx.fill();
    }

    pathify(obj:Drawable): Path2D {
        const result = new Path2D();
        const { shape, pos, dir } = obj;
        for (const ll of shape) {
            const path = new Path2D();
            this.moveTo(rotateAndTranslate(pos, dir || 0, ll[0]), path);
            for (var i=1; i < ll.length; i++) {
                this.clineTo(rotateAndTranslate(pos, dir || 0, ll[i]), path);
            }
            result.addPath(path);
        }
        return result;
    }

    strokeDrawable(obj:Drawable) {
        this.ctx.stroke(this.pathify(obj));
    }

    isPointIn(obj:Drawable, p:ComplexNum): boolean {
        const _p = this.toCanvas(p);
        return this.ctx.isPointInPath(this.pathify(obj), _p.x, _p.y);
    }
}