import { ComplexNum, muls, polar } from "./complex";
import { Constants } from "./constants";
import { asEuclideanDistance, normalizeAngle, rotate, translate } from "./hyperbolic";
import { explosion, StateWithParticles } from "./particle";
import { random, randomDirection } from "./random";
import Vector, { move } from "./vector";
import { HyperbolicCanvas, LineList, Shape, StateWithCollisionService } from "./canvas";

export type Asteroid = {
    rank: number
    pos: ComplexNum
    vel: Vector
    dir: number
    rot: number
    shape: Shape
}

export type StateWithAsteroids = {
    asteroids: Set<Asteroid>
}

const asteroidRadius: Array<number> = [0];
for (var rank=1; rank <= 3; rank++) {
    asteroidRadius.push(Constants.ShipLen * 0.3 * Math.pow(rank, 1.5));
}

export function makeAsteroidShape(radius: number, numVertices:number):Shape {
    const dtheta = (Math.PI*2)/numVertices;
    const ll:LineList = [];
    for (var i=0; i < numVertices; i++) {
        ll.push(polar(0.75 * radius + random(0, 0.25 * radius), i * dtheta));
    }
    ll.push(ll[0]);
    return [ll];
}

export function makeAsteroid(rank:number, pos?:ComplexNum, vel?: Vector): Asteroid {
    if (typeof pos === 'undefined') {
        const d = asEuclideanDistance(random(Constants.WorldRadius / 2, Constants.WorldRadius));
        pos = polar(d, randomDirection());
    }
    if (typeof vel === 'undefined') {
        vel = new Vector(random(Constants.MaxSpeed / 5, Constants.MaxSpeed), randomDirection());
    }
    const shape = makeAsteroidShape(asteroidRadius[rank], 10);
    return {
        rank, pos,
        vel,
        dir: 0,
        rot: random(-Constants.RotSpeed, Constants.RotSpeed),
        shape,
    };
}

export function splitAsteroid(state: StateWithAsteroids & StateWithParticles & StateWithCollisionService, t:number, a:Asteroid): Array<Asteroid> {
    const { asteroids, collisionSvc } = state;

    asteroids.delete(a);

    const newRoids: Array<Asteroid> = [];
    if (a.rank > 1) {
        const alpha = randomDirection();
        const n = Math.round(random(2, 3));
        const da = (2 * Math.PI) / n;
        for (var j = 0; j < n; j++) {
            const angle = alpha + da * j;
            const pos = translate(muls(a.pos, -1), rotate(angle, { x: asteroidRadius[a.rank - 1], y: 0 }));
            const vel = new Vector(a.vel.r * 1.25, angle);
            const newRoid = makeAsteroid(a.rank - 1, pos, vel);
            asteroids.add(newRoid);
            newRoids.push(newRoid);
        }
    }
    explosion(state, {
        t,
        n: 10 * a.rank * a.rank,
        entity: a,
        filter: (p) => {
            for (const newA of newRoids) {
                if (collisionSvc.isPointIn(newA, p)) {
                    return true;
                }
            }
            return !collisionSvc.isPointIn(a, p);
        }
    });
    return newRoids;
}

export function updateAsteroids(state: StateWithAsteroids, t:number, dt:number) {
    const { asteroids } = state;
    for (const a of asteroids) {
        a.dir = normalizeAngle(a.dir + a.rot * dt);
        move(a, dt, Constants.WorldRadius);
    }
}

export function drawAsteroids(state: StateWithAsteroids, canvas: HyperbolicCanvas) {
    for (const a of state.asteroids) {
        canvas.ctx.strokeStyle = 'white'
        canvas.ctx.lineWidth = 1;
        canvas.ctx.fillStyle = 'black';
        const path = canvas.pathify(a);
        canvas.ctx.stroke(path);
        canvas.ctx.fill(path);
    }
}