
export class SoundEffect {
    private readonly channels:Array<HTMLAudioElement>
    private chan:number

    constructor(url:string,numChannels:number) {
        this.channels = [];
        for (var i=0; i < numChannels; i++) {
            this.channels.push(new Audio(url));
        }
        this.chan = 0;
    }

    play() {
        this.channels[this.chan].play();
        this.chan = (this.chan+1)%this.channels.length;
    }
}