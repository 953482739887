export interface ComplexNum {
    x: number
    y: number
}

export type ComplexFn = (p:ComplexNum) => ComplexNum

export function complex(x:number,y:number) { return {x,y} }

export function polar(r:number,theta:number) {
    return {x:r*Math.cos(theta), y:r*Math.sin(theta)};
}

/** complex addition */
export function add(a:ComplexNum,b:ComplexNum): ComplexNum {
    return { x: a.x+b.x, y: a.y+b.y };
}

/** complex subtraction */
export function sub(a:ComplexNum,b:ComplexNum): ComplexNum {
    return { x: a.x-b.x, y: a.y-b.y };
}

/** complex conjugate */
export function conjugate(a:ComplexNum): ComplexNum {
    return {x: a.x, y: -a.y};
}

/** complex multiplication by scalar */
export function muls(a: ComplexNum, n: number): ComplexNum {
    return {x: a.x*n, y: a.y*n};
}

/** complex division by scalar */
export function divs(a: ComplexNum, n: number): ComplexNum {
    return {x: a.x/n, y: a.y/n};
}

/** complex multiplication */
export function mul(a:ComplexNum, b:ComplexNum): ComplexNum {
    return {
        x: a.x*b.x - a.y*b.y,
        y: a.y*b.x + a.x*b.y
    }
}

/** complex modulus */
export function modulus(a:ComplexNum): number {
    return Math.sqrt(a.x*a.x+a.y*a.y);
}

/** complex division */
export function div(a:ComplexNum, b:ComplexNum): ComplexNum {
    return divs( mul(a, conjugate(b)), b.x*b.x + b.y*b.y );
}

export function lerp(a:number,b:number,t:number): number {
    return a*(1-t)+b*t;
}

export function lerp2(a:ComplexNum, b:ComplexNum, t:number): ComplexNum {
    return complex( lerp(a.x, b.x, t), lerp(a.y, b.y, t) );
}