import { rotate, rotateAndTranslate } from './hyperbolic';
import { CollisionService, HyperbolicCanvas } from './canvas';
import { drawParticles, explosion, Particle, updateParticles } from './particle';
import { drawShip, initialShipState, Ship, ShipShape, updateShip } from './ship';
import { Constants } from './constants';
import { Bullet, drawBullets, updateBullets } from './bullets';
import { Asteroid, drawAsteroids, makeAsteroid, splitAsteroid, updateAsteroids } from './asteroid';
import { keyState } from './input';
import { add, muls } from './complex';
import { SoundEffect } from './audio';

const realCanvas = document.getElementById('canvas') as HTMLCanvasElement;
realCanvas.height = window.innerHeight;
realCanvas.width = window.innerWidth;
const canvas = new HyperbolicCanvas(realCanvas);
const ctx = canvas.ctx;

const bang = new SoundEffect('bang.wav', 4);

type GameState = {
    t: number,
    level: number,
    score: number,
    shipsRemaining: number,
    respawnTime?: number,
    levelResetTime?: number,
    paused: boolean,
    ship: Ship | null,
    bullets: Set<Bullet>,
    asteroids: Set<Asteroid>,
    particles: Set<Particle>,
    collisionSvc: CollisionService,
}

var gameState: GameState;

function initialAsteroids(level: number): Set<Asteroid> {
    const asteroids = new Set() as Set<Asteroid>;
    for (var i=0; i < 5+3*level; i++) {
        asteroids.add(makeAsteroid(3));
    }
    return asteroids;
} 

function initialState(t:number): GameState {
    const asteroids : Set<Asteroid> = new Set();
    for (var i=0; i < 20; i++) {
        asteroids.add(makeAsteroid(3));
    }
    return {
        t,
        level: -1,
        score: 0,
        shipsRemaining: 0,
        paused: false,
        ship: null,
        bullets: new Set(),
        asteroids,
        particles: new Set(),
        collisionSvc: canvas,
    }
}

function initialize(millis:DOMHighResTimeStamp) {
    gameState = initialState(millis/1000);
    window.requestAnimationFrame(gameLoop);
} 

function gameLoop(millis:DOMHighResTimeStamp) {
    const t = millis/1000;
    handleInput();
    update(t);
    draw(t);
    window.requestAnimationFrame(gameLoop);
}

function handleInput() {
    if ((gameState.level === -1 || (!gameState.ship && gameState.shipsRemaining === 0)) && keyState.Enter) {
        gameState.level = 0;
        gameState.score = 0;
        gameState.shipsRemaining = 3;
        gameState.asteroids = initialAsteroids(gameState.level);
        gameState.ship = initialShipState();
    }

    if (keyState.p) {
        gameState.paused = !gameState.paused;
        keyState.p = false;
    }

    if (gameState.ship) {
        if (keyState['ArrowLeft'] === true && keyState['ArrowRight'] === false) {
            gameState.ship.rotation = 'ccw';
        } else if (keyState['ArrowLeft'] === false && keyState['ArrowRight'] === true) {
            gameState.ship.rotation = 'cw';
        } else {
            gameState.ship.rotation = 'none';
        }

        gameState.ship.isThrusting = (keyState['ArrowUp'] === true);

        gameState.ship.isShooting = (keyState.Control === true);
    }
}

function update(t:number) {
    if (gameState.t >= t) return;
    const dt = (t - gameState.t);

    if (!gameState.paused) {
        if (gameState.ship === null) {
            if (gameState.shipsRemaining > 0 && gameState.respawnTime && t > gameState.respawnTime) {
                delete gameState.respawnTime;
                gameState.shipsRemaining--;
                gameState.ship = initialShipState();
            }
        } else {
            updateShip(gameState, t, dt);
            if (gameState.asteroids.size === 0 && !gameState.levelResetTime) {
                gameState.levelResetTime = t+3;
            } else if (gameState.levelResetTime && t > gameState.levelResetTime) {
                delete gameState.levelResetTime;
                gameState.level++;
                gameState.asteroids = initialAsteroids(gameState.level);
            }
        }
        updateBullets(gameState, t, dt);
        updateAsteroids(gameState, t, dt);
        handleCollisions(t, collide());
        updateParticles(gameState, t, dt);
    }

    gameState.t = t;
}

type CollisionResult = {
    bullets: Set<Bullet>
    asteroids: Set<Asteroid>
    ship: boolean
}

function collide(): CollisionResult {
    const result: CollisionResult = {
        bullets: new Set(),
        asteroids: new Set(),
        ship: false
    };

    const { asteroids, bullets, ship } = gameState;
    for (const a of asteroids) {
        for (const b of bullets) {
            if (result.bullets.has(b)) continue;

            if (canvas.isPointIn(a, b.pos)) {
                result.bullets.add(b);
                result.asteroids.add(a);
            }
        }
        if (ship) {
            for (const ll of ship.shape) {
                for (const p of ll) {
                    if (canvas.isPointIn(a, rotateAndTranslate(ship.pos, ship.dir, p))) {
                        result.ship = true;
                        result.asteroids.add(a);
                    }
                }
            }
        }
    }

    return result;
}

function handleCollisions(t:number, collisions: CollisionResult) {
    for (const b of collisions.bullets) {
        gameState.bullets.delete(b);
    }
    if (collisions.asteroids.size > 0) {
        bang.play();
    }
    for (var a of collisions.asteroids) {
        gameState.score += 100 * (4-a.rank);
        splitAsteroid(gameState, t, a);
    }
    if (collisions.ship && gameState.ship) {
        var ship = gameState.ship;
        explosion(gameState, {
            t,
            n: 30,
            entity: gameState.ship,
            filter: (p) => {
                return !canvas.isPointIn(ship, p);
            }
        });
        gameState.ship = null;
        if (gameState.shipsRemaining > 0) {
            gameState.respawnTime = t+5;
        }
    }
}

function drawUI() {
    const { width } = canvas.canvas;
    if (gameState.level >= 0) {
        ctx.font = '30px monospace';
        ctx.textAlign = 'left';
        ctx.strokeStyle = 'black';
        ctx.strokeText(gameState.score.toString(), 30, 60);

        var pos = {x:width-60,y:60};
        for (var i=0; i < gameState.shipsRemaining; i++) {
            ctx.beginPath();
            var first = false;
            for (var ll of ShipShape) { 
                for (var p of ll) {
                    const _p = add(pos, rotate(-Math.PI / 2, muls(p, canvas.scale)));
                    if (first) {
                        ctx.moveTo(_p.x, _p.y);
                        first = false;
                    } else {
                        ctx.lineTo(_p.x, _p.y);
                    }
                }
            }
            ctx.stroke();
            pos.x -= canvas.scale*Constants.ShipLen;
        }
    }
}

function drawMessages(t:number) {
    const { width, height } = canvas.canvas;
    ctx.font = '30px monospace';
    ctx.textAlign = 'center';
    ctx.strokeStyle = 'white';
    var msg:string|null = null;
    if (gameState.level === -1) {
        msg = 'PRESS ENTER';
    } else if (gameState.paused) {
        msg = 'PAUSED';
    } else if (gameState.respawnTime) {
        const timeLeft = gameState.respawnTime - t;
        if (timeLeft > 0 && timeLeft < 3) {
            msg = `${Math.ceil(timeLeft)}`;
        }
    } else if (!gameState.ship) {
        msg = `GAME OVER`;
    }
    if (msg !== null) {
        ctx.strokeText(msg, width / 2, height / 2)
    }
}

function draw(t:number) {
    // Clear the whole canvas.
    ctx.globalCompositeOperation = 'source-over' // the default
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    drawUI();

    // Make the wrapped circle fully transparent
    ctx.fillStyle = 'black';
    ctx.globalCompositeOperation = 'destination-out'
    ctx.lineWidth = 0;
    ctx.beginPath();
    const _o = canvas.toCanvas(Constants.Origin);
    const wrapDist = Math.tanh(Constants.WorldRadius/2);
    ctx.ellipse( _o.x, _o.y, wrapDist*canvas.scale, wrapDist*canvas.scale, 0, 0, Math.PI*2);
    ctx.fill();
    ctx.stroke();

    // Draw the remainder behind the border.
    ctx.globalCompositeOperation = 'destination-over'

    drawMessages(t);

    // Draw the game state.
    drawShip(gameState, canvas);
    drawBullets(gameState, canvas);
    drawParticles(gameState, t, canvas);
    drawAsteroids(gameState, canvas);

    // Finally, fill in what's left with black.
    ctx.fillStyle = 'black';
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 0;
    ctx.beginPath();
    ctx.ellipse( _o.x, _o.y, wrapDist*canvas.scale, wrapDist*canvas.scale, 0, 0, Math.PI*2);
    ctx.fill();
    ctx.stroke();
}

window.requestAnimationFrame(initialize);