import Color from "color";
import { fireBullet, StateWithBullets } from "./bullets";
import { complex, ComplexNum } from "./complex";
import { Constants } from "./constants";
import { normalizeAngle } from "./hyperbolic";
import { particleJet, StateWithParticles } from "./particle";
import { random } from "./random";
import Vector, { move } from "./vector";
import { HyperbolicCanvas, Shape } from "./canvas";
import { SoundEffect } from "./audio";

export type Ship = {
    pos: ComplexNum
    dir: number
    rot: number
    vel: Vector
    isThrusting: boolean
    isShooting: boolean
    rotation: 'none' | 'cw' | 'ccw'
    lastFireTime: number
    shape: Shape
}

export const ShipShape: Shape = [
    [
        complex(-Constants.ShipLen / 2, -Constants.ShipLen / 3),
        complex(Constants.ShipLen / 2, 0),
        complex(-Constants.ShipLen / 2, Constants.ShipLen / 3),
    ],
    [
        complex(-Constants.ShipLen / 2, -Constants.ShipLen / 3),
        complex(-Constants.ShipLen / 2, Constants.ShipLen / 3),
    ],
]

export function initialShipState(): Ship {
    return {
        pos: Constants.Origin,
        dir: -Math.PI / 2,
        rot: 0,
        vel: new Vector(0, 0),
        isThrusting: false,
        isShooting: false,
        rotation: 'none',
        lastFireTime: -Constants.ReloadTime,
        shape: ShipShape,
    }
}

const exhaustColors = [
    new Color('#ff7f11'),
    new Color('white'),
    //new Color('#ff3f00'),
]

const pew = new SoundEffect('pew.wav', 2);

export type StateWithShip = {
    ship: Ship | null
}

export function updateShip(state: StateWithShip & StateWithParticles & StateWithBullets, t:number, dt: number) {
    const { ship, bullets } = state;
    if (!ship) return;

    if (ship.rotation !== 'none') {
        ship.rot = Constants.RotSpeed*dt*(ship.rotation === 'cw' ? 1 : -1);
        ship.dir = normalizeAngle(ship.dir + ship.rot);
    }
    if (ship.isThrusting) {
        particleJet(state, {
            rate: 50, t, dt, pos: ship.pos, speed: Constants.MaxSpeed*4,
            fade: true,
            ttl: random(0.25,0.35),
            colors: exhaustColors,
            minTheta: ship.dir-Math.PI-0.1,
            maxTheta: ship.dir-Math.PI+0.1,
        });
        ship.vel.add(new Vector(Constants.ShipAccel*dt, ship.dir));
    }
    if (ship.vel.r > Constants.MaxSpeed) {
        ship.vel.r = Constants.MaxSpeed;
    }

    move(ship, dt, Constants.WorldRadius);

    if (ship.isShooting && bullets.size < Constants.MaxBullets && ( t - ship.lastFireTime ) >= Constants.ReloadTime ) {
        ship.lastFireTime = t;
        fireBullet(state, t, ship);
        pew.play();
    }
}

export function drawShip(state: StateWithShip, canvas: HyperbolicCanvas) {
    if (state.ship) {
        canvas.ctx.strokeStyle = 'white';
        canvas.ctx.fillStyle = 'black';
        const shipPath = canvas.pathify(state.ship);
        canvas.ctx.stroke(shipPath);
        canvas.ctx.fill(shipPath);
    }
}