import Color from "color";
import { HyperbolicCanvas, Shape } from "./canvas";
import { ComplexNum, lerp, modulus, muls, polar } from "./complex";
import { arcAngle, asHyperbolicDistance, normalizeAngle, rotate, translate } from "./hyperbolic";
import { random, randomDirection } from "./random";
import Vector, { Movable, move } from "./vector";

export type Particle = {
    pos: ComplexNum
    vel: Vector
    firedTime: number
    ttl: number
    color: Color
    fade: boolean
    radius: number
}

export type StateWithParticles = {
    particles: Set<Particle>
}

type SpawnOpts = {
    colors?: Array<Color>
    fade?: boolean
    ttl?: number
    radius?: number
}
const defaultOpts = {
    colors: [new Color('white')],
    fade: true,
    ttl: 1,
    radius: 1,
}

type Entity = Movable & { shape: Shape, rot?: number }

function pickColor(colors:Array<Color>): Color {
    return colors[Math.round(random(0, colors.length - 0.5))]
}

type ExplodeArgs = {
    t: number
    n: number
    entity: Entity
    filter?: (p: ComplexNum) => boolean
}

const explodeDefaults = {
    filter: (_:ComplexNum) => false
}

export function explosion(state: StateWithParticles, args: ExplodeArgs) {
    const { particles } = state;
    const { t, entity, filter } = Object.assign({}, explodeDefaults, args);
    var n = args.n;
    var r = 0;
    // find bounding radius
    for (var ll of entity.shape) {
        for (var p of ll) {
            var d = modulus(p);
            if (d > r) {
                r = d
            };
        }
    }
    var i = 0;
    while (n > 0 && i < 300) {
        i++;
        // try to generate a point inside a donut r/2 wide and centered on entity
        const d = random(r/2,r)
        var p = polar(d, randomDirection());
        p = translate(muls(entity.pos, -1), rotate(entity.dir || 0, p))
        if (filter(p)) continue;

        const offsetAngle = arcAngle(p, entity.pos);
        // start w/ the entity's speed
        const vel = entity.vel.clone();
        // particles move away from the center; faster the closer to the center they are
        const speed = lerp(0.1,0.5, (d-(r/2)) / (r/2));
        vel.add(new Vector(speed, offsetAngle));
        if (entity.rot) {
            // particles keep their tangential velocity
            vel.add(new Vector(entity.rot * asHyperbolicDistance(d), normalizeAngle(offsetAngle + Math.PI / 2)));
        }
        particles.add({
            pos: p,
            color: new Color('white'),
            fade: true,
            firedTime: t,
            radius: random(0.5,5),
            ttl: 0.75,
            vel: vel,
        });
        n--;
    }
}

export function particleJet(state: StateWithParticles, args: {rate: number, t:number, dt:number, pos:ComplexNum, speed: number, minTheta:number, maxTheta:number}&SpawnOpts): void {
    const { particles } = state;
    const { radius, rate, t, dt, pos, speed, minTheta, maxTheta, colors, fade, ttl } = Object.assign({}, defaultOpts, args);
    const n = Math.random();
    if (n >= rate * dt) {
        return;
    }
    particles.add({
        pos,
        vel: new Vector( speed, normalizeAngle(random(minTheta,maxTheta)) ),
        color: pickColor(colors),
        radius,
        fade,
        firedTime: t,
        ttl,
    });
}

export function updateParticles(state: StateWithParticles, t:number, dt:number) {
    const { particles } = state;
    for (const p of particles) {
        if (p.firedTime + p.ttl < t) {
            particles.delete(p);
        } else {
            move(p, dt);
        }
    }
}

export function drawParticles(state: StateWithParticles, t:number, canvas:HyperbolicCanvas) {
    const { particles } = state;
    for (const { radius, pos, firedTime, ttl, fade, color } of particles) {
        var drawColor = color;
        if (fade) {
            drawColor = drawColor.darken( (t-firedTime)/ttl );
        }
        canvas.fillDot(pos, radius, color);
    }
}