import { ComplexNum, conjugate, div, modulus, mul, muls, polar, sub } from './complex';

export function colinear(a:ComplexNum,b:ComplexNum,c:ComplexNum,epsilon=0): boolean {
    const ab = sub(b,a);
    const ac = sub(c,a);
    const diff = Math.abs(ab.x*ac.x+ab.y*ac.y) - modulus(ab)*modulus(ac)
    return Math.abs(diff) <= epsilon;
}

export function normalizeAngle(theta: number) {
    while (theta > 2 * Math.PI) { theta -= 2 * Math.PI; }
    while (theta < 0) { theta += 2 * Math.PI; }
    return theta;
}

export function argument(n:ComplexNum): number {
    return normalizeAngle(Math.atan2(n.y, n.x));
}

export function arcAngle(p:ComplexNum,center:ComplexNum = {x:0,y:0}) {
    return argument(sub(p,center));
}

export function crossRatio(a:ComplexNum,p:ComplexNum,q:ComplexNum,b:ComplexNum): number {
    return Math.log((modulus(sub(a, q)) * modulus(sub(p, b))) / (modulus(sub(a, p)) * modulus(sub(q, b))));
}

function quadraticRoots(a: number, b: number, c: number): [number,number] {
    const addend = Math.sqrt(b * b - 4 * a * c);
    const atwice = 2 * a;
    return [(-b + addend) / atwice, (-b - addend) / atwice];
}

/**
 * Find the points a and b where the unit circle intersects the orthogonal circle with center c.
 * 
 * @param c center of the intersecting circle
 * @returns two points of intersection
 */
export function orthoIntersection(c:ComplexNum): [a:ComplexNum,b:ComplexNum] {
    // We want to solve for z such that:
    // [1]  |z-c|^2 + 1 = |c|^2     z, c, a the origin form a right triangle
    // [2]  |z| = 1                 z is on the unit circle
    // 
    // sqrt(z.x^2 + z.y^2) = 1      find an expression for z.y using [2]
    // z.x^2 + z.y^2 = 1
    // z.y^2 = 1 - z.x^2
    // z.y = +|- sqrt(1 - z.x^2)
    //
    // |(z.x-c.x) + (z.y-c.y)*i|^2 + 1 = c.x^2 + c.y^2
    // (z.x-c.x)^2 + (z.y-c.y)^2   + 1 = c.x^2 + c.y^2
    // z.x^2 - 2*c.x*z.x + c.x^2 + z.y^2 - 2*c.y*z.y + c.y^2 + 1 = c.x^2 + c.y^2
    // z.x^2 - 2*c.x*z.x         + z.y^2 - 2*c.y*z.y         + 1 = 0
    // z.x^2 - 2*c.x*z.x + 1 - z.x^2 +|- 2*c.y*sqrt(1 - z.x^2) + 1 = 0
    //       - 2*c.x*z.x             +|- 2*c.y*sqrt(1 - z.x^2) + 2 = 0
    //       -   c.x*z.x             +|-   c.y*sqrt(1 - z.x^2) + 1 = 0
    //       -   c.x*z.x             +|-   c.y*sqrt(1 - z.x^2) + 1 = 0
    // (1-c.x*z.x)/c.y = +|- sqrt(1 - z.x^2)
    // (1 - c.x*z.x)^2 / c.y^2 = 1 - z.x^2
    // (1 - c.x*z.x)^2             = c.y^2 - c.y^2*z.x^2
    // 1 - 2*c.x*z.x + c.x^2*z.x^2 = c.y^2 - c.y^2*z.x^2
    // 1 - 2*c.x*z.x + c.x^2*z.x^2 - c.y^2 + c.y^2*z.x^2 = 0
    // (c.x^2 + c.y^2)*z.x^2 - 2*c.x*z.x + 1 - c.y^2 = 0
    //
    // The roots of the above get us our two x values. To get the correct
    // y for each x, we need to substitute into:
    // 
    // z.x^2 - 2*c.x*z.x + z.y^2 - 2*c.y*z.y + 1 = 0
    // z.x^2 - 2*c.x*z.x + 1 - z.x^2 - 2*c.y*z.y + 1 = 0
    //       - 2*c.x*z.x             - 2*c.y*z.y + 2 = 0
    //       -   c.x*z.x             -   c.y*z.y + 1 = 0
    // c.y*z.y = 1 - c.x*z.x
    // z.y = (1 - c.x*z.x)/c.y
    if (Math.abs(c.x) > Math.abs(c.y)) {
        const [y0, y1] = quadraticRoots(c.y * c.y + c.x * c.x, -2 * c.y, 1 - c.x * c.x);
        return [
            { y: y0, x: (1 - c.y * y0) / c.x },
            { y: y1, x: (1 - c.y * y1) / c.x },
        ];
    } else {
        const [x0, x1] = quadraticRoots(c.x * c.x + c.y * c.y, -2 * c.x, 1 - c.y * c.y);
        return [
            { x: x0, y: (1 - c.x * x0) / c.y },
            { x: x1, y: (1 - c.x * x1) / c.y },
        ];
    }
}

function orthogonalCircleCenter(p:ComplexNum,q:ComplexNum):ComplexNum {
    // TODO: under what conditions should we pick the first branch
    // in order to improve stability?
    if (p.y === 0) {
        const m_p = -p.y / p.x
        const m_q = -q.y / q.x
        const b_p = (p.y * p.y + p.x * p.x + 1) / (2 * p.x)
        const b_q = (q.y * q.y + q.x * q.x + 1) / (2 * q.x)
        const y = (b_q - b_p) / (m_p - m_q);
        const x = m_p * y + b_p;
        return { x, y }
    } else {
        const m_p = -p.x / p.y
        const m_q = -q.x / q.y
        const b_p = (p.x * p.x + p.y * p.y + 1) / (2 * p.y)
        const b_q = (q.x * q.x + q.y * q.y + 1) / (2 * q.y)
        const x = (b_q - b_p) / (m_p - m_q);
        const y = m_p * x + b_p;
        return { x, y }
    }
}

export type PoincareArc = {
    isArc: true
    a: ComplexNum
    b: ComplexNum
    center: ComplexNum
    radius: number
    alpha: number
    beta: number
}

export type PoincareLine = {
    isArc: false
    a: ComplexNum
    b: ComplexNum
}

export type PoincareCline = PoincareArc | PoincareLine

export function poincareCline(a: ComplexNum, b: ComplexNum): PoincareCline {
    const isArc = !colinear(a, b, { x: 0, y: 0 }, 0.000000000000001)
    if (isArc) {
        const center = orthogonalCircleCenter(a, b);
        const alpha = arcAngle(a, center);
        const beta = arcAngle(b, center);
        const radius = modulus(sub(a, center));
        return { a, b, isArc, center, alpha, beta, radius }
    } else {
        return { isArc, a, b }
    }
}

/**
 * Distance from p to q in the Poincare model of hyperbolic geometry, where a and b are
 * the ideal points incident on the hyperbolic line containing p and q, with a closer to p.
 * When not provided, a and b are computed.
 */
export function poincareDistance(p:ComplexNum, q:ComplexNum): number {
    const cline = poincareCline(p, q);
    if (cline.isArc) {
        const [a,b] = orthoIntersection(cline.center)
        return Math.abs(crossRatio(a,p,q,b));
    } else {
        const v = sub(p, q);
        const angle = Math.atan2(v.y, v.x);
        const a = polar(1, angle);
        const b = muls(a, -1);
        return Math.abs(crossRatio(a,p,q,b));
    }
}

export function rotate(theta:number, z:ComplexNum) {
    return mul({ x: Math.cos(theta), y: Math.sin(theta) }, z);
}

export function translate(z0:ComplexNum, z:ComplexNum) {
    return div(sub(z, z0), sub({ x: 1, y: 0 }, mul(conjugate(z0), z)))
}

export function asEuclideanDistance(hyperbolicDistance: number): number {
    return Math.tanh(hyperbolicDistance/2);
}

export function asHyperbolicDistance(euclideanDistance: number): number {
    return 2*Math.atanh(euclideanDistance);
}

export function rotateAndTranslate(pos:ComplexNum, dir:number, z:ComplexNum) {
    return translate(muls(pos,-1), rotate(dir, z));
}